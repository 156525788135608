/* eslint-disable  @typescript-eslint/no-explicit-any */
import { InjectionKey } from 'vue';

/**
 * Vue TS strict inject provided (avoid undefined)
 * @param key
 * @param fallback
 */
export const injectStrict = <T>(key: InjectionKey<T>, fallback?: T): T => {
    const resolved = inject(key, fallback);

    if (!resolved) {
        throw new Error(`Could not resolve ${key.description}`);
    }

    return resolved;
};

/**
 * Remove a trailing slash from URL
 * @param url
 */
export const normalizeUrl = (url: string): string => {
    return url.endsWith('/') ? url.slice(0, -1) : url;
};

/**
 * Check if the given data has files.
 *
 * @param data
 */
export const hasFiles = (data: File | Blob | FileList | Record<string, any>): boolean => {
    return (
        data instanceof File ||
        data instanceof Blob ||
        data instanceof FileList ||
        (typeof data === 'object' &&
            data !== null &&
            Object.values(data).find((value) => hasFiles(value)) !== undefined)
    );
};

/**
 * Get Input Error key from name (Laravel validation format X.Y.Z)
 * @param name
 */
export const getInputErrorKey = (name: string) => name.replace(/[[\].]+/g, '.').replace(/\.*$/, '');
