<script setup lang="ts">
definePage({
    meta: {
        layout: 'default', // potřeba kvůli potomkům jinak se zanoří layout
    },
});
</script>

<template>
    <management-page-users />
</template>
