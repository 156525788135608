<script lang="ts">
export { useMonitoringDeviceSettingsSystemLoader } from '~/loaders/useMonitoringDeviceSettingsSystemLoader';
</script>

<script setup lang="ts">
import { useMonitoringDeviceSettingsSystemLoader } from '~/loaders/useMonitoringDeviceSettingsSystemLoader';

definePage({
    meta: {
        layout: 'default',
    },
});

const { isMenuLoading } = useMonitoring();

const { isLoading, data } = useMonitoringDeviceSettingsSystemLoader();
</script>

<template>
    <app-page padding :loading="isMenuLoading || (isLoading && !data)">
        <app-page-header title="Nastavení střídače" />
        <div class="row q-col-gutter-lg justify-center">
            <div class="col-12 row q-col-gutter-lg justify-center">
                <div class="col-12 col-lg-6">
                    <monitoring-form-settings-general />
                </div>
                <div class="col-12 col-lg-6">
                    <monitoring-form-settings-corrections class="fit" />
                </div>
            </div>
        </div>
    </app-page>
</template>
