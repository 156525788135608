import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useAuthStore } from '~/boot/auth/store';
import { ApiClientResponse, ApiServiceOpts } from '~/boot/plugins/apiClient/_types';
import { apiClientParseRawResponse } from '~/boot/plugins/apiClient/_response';
import { isUndefined } from 'lodash-es';
import { Notify } from 'quasar';
import { dump } from '~/utils/debug';
import { apiClientAuthErrors } from '~/boot/plugins/apiClient/_config';

export const useApiClientInterceptors = (serviceOpts?: ApiServiceOpts) => {
    const _debug: boolean = serviceOpts?.debug || false;

    const requestInterceptor = (request: InternalAxiosRequestConfig) => {
        const authStore = useAuthStore();

        if (!serviceOpts?.public && authStore.token?.Value) {
            request.headers.setAuthorization(`Bearer ${authStore.token.Value}`);
        }

        return request;
    };

    const responseInterceptor = async (rawResponse: AxiosResponse): Promise<ApiClientResponse> =>
        apiClientParseRawResponse(rawResponse);

    const responseErrorInterceptor = async ({ response: rawResponse }: AxiosError): Promise<ApiClientResponse> => {
        if (isUndefined(rawResponse)) {
            Notify.create({
                group: 'api:client',
                type: 'negative',
                message: 'API',
                caption: 'Network error',
                actions: [{ icon: 'las la-times', color: 'white' }],
                timeout: 10000,
            });
            throw new Error('[ApiClient] Network error.');
        }

        const authStore = useAuthStore();
        const response = await apiClientParseRawResponse(rawResponse);

        if (apiClientAuthErrors.includes(response.status)) {
            await authStore.logout(true);
        }

        if (_debug) {
            dump(response);
        }

        return response;
    };
    return {
        requestInterceptor,
        responseInterceptor,
        responseErrorInterceptor,
    };
};
