import { Cookies, Dialog, Loading, LocalStorage, Notify, Quasar, SessionStorage } from 'quasar';
import { App } from 'vue';
import { dump } from '~/utils/debug';
import langCs from 'quasar/lang/cs';

// Import icon libraries
import '@quasar/extras/line-awesome/line-awesome.css';
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css';
import '@quasar/extras/mdi-v7/mdi-v7.css';
import iconSet from 'quasar/icon-set/mdi-v7';

// Import Quasar css
import '~/css/index.scss';

const bootQuasar = (app: App) => {
    const cookieDark = Cookies.get<'auto' | 'true' | 'false' | null>('dark') || 'auto';

    const dark = /^auto$/i.test(cookieDark) ? 'auto' : /^true$/i.test(cookieDark);

    // https://quasar.dev/start/vite-plugin/#using-quasar
    app.use(Quasar, {
        iconSet: iconSet,
        plugins: {
            // https://quasar.dev/quasar-plugins/notify
            Notify,
            // https://quasar.dev/quasar-plugins/dialog
            Dialog,
            // https://quasar.dev/quasar-plugins/loading
            Loading,
            // https://quasar.dev/quasar-plugins/web-storage
            LocalStorage,
            SessionStorage,
        },
        config: {
            // https://quasar.dev/quasar-plugins/dark
            dark: dark,
            notify: {
                position: 'top-right',
                timeout: 5000,
            },
        },
        lang: langCs,
    });

    dump('[Quasar] -> Booted');
};

export default bootQuasar;
