/* eslint-disable  @typescript-eslint/no-explicit-any */
import { pick } from 'lodash-es';
import { AuthData, AuthToken, AuthUser } from '~/boot/auth/_types';
import { rawToRoleListArray } from '~/api/endpoints/management/users/_transformers';

export const rawToAuthToken = (data: any): AuthToken => {
    return {
        Value: data.Token,
        Validated: false,
    };
};

export const rawToAuthUser = (data: any): AuthUser | null => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'FirstName', 'LastName', 'Login', 'IsAdmin', 'Email', 'Phone', 'IsActive']),
        FullName: `${data.FirstName} ${data.LastName}`,
        ApplicationRoles: rawToRoleListArray(data.ApplicationRoles),
    };
};

export const rawToAuthData = (data: any): AuthData | null => {
    if (data == null) return null;
    return {
        Token: rawToAuthToken(data),
        User: rawToAuthUser(data.User),
    };
};
