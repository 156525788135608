import { $ResetPinia, useDirectRouter } from '~/boot/pinia';
import { AuthToken, AuthUser } from '~/boot/auth/_types';
import { Cookies } from 'quasar';

export const useAuthStore = defineStore('auth', () => {
    const cookieTokenKey = 'auth_token';
    const router = useDirectRouter();
    const { storageUserId, storageDeviceId } = useMonitoring();

    const _user = ref<AuthUser | null>(null);
    const _token = ref(Cookies.get<AuthToken | null>(cookieTokenKey));
    const loading = ref(false);

    const loggedIn = computed(() => _user.value != null);
    const user = computed(() => _user.value);
    const token = computed(() => _token.value);

    /**
     * Reset Store state
     */
    const _resetState = (): void => {
        _user.value = null;
        _token.value = null;
        loading.value = false;

        // todo: vyřešit lépe
        window.Echo.options.bearerToken = undefined;
        window.Echo.connect();
    };

    const setToken = (payload: AuthToken | null): void => {
        Cookies.set(cookieTokenKey, JSON.stringify(payload), {
            path: '/',
            domain: `.${window.location.host}`,
        });
        _token.value = payload;
    };

    const validateToken = (): void => {
        if (_token.value != null) {
            _token.value.Validated = true;
            Cookies.set(cookieTokenKey, JSON.stringify(_token.value), {
                path: '/',
                domain: `.${window.location.host}`,
            });
        }
    };

    const setUser = (payload: AuthUser | null): void => {
        _user.value = payload;
    };

    const finishLogin = async (payload: AuthUser): Promise<void> => {
        const { currentRoute } = router;
        validateToken();
        setUser(payload);

        // todo: vyřešit lépe
        window.Echo.options.bearerToken = _token.value?.Value;
        window.Echo.connect();

        if (currentRoute.value.name == '/auth/login') {
            if (!storageUserId.value) {
                await router.push({ name: '/' });
                return;
            }

            if (storageDeviceId.value) {
                await router.push({ name: '/devices/[deviceId]/', params: { deviceId: storageDeviceId.value } });
                return;
            }

            await router.push({ name: '/devices/' });
        }
    };

    const logout = async (preserveQuery = false, resetStore = true): Promise<void> => {
        const { currentRoute } = router;

        Cookies.remove(cookieTokenKey, {
            path: '/',
            domain: `.${window.location.host}`,
        });
        _resetState();

        if (currentRoute.value.name !== '/auth/login') {
            await router.replace({
                name: '/auth/login',
                query: preserveQuery
                    ? {
                          to: <string>currentRoute.value.name,
                          params: JSON.stringify(currentRoute.value.params),
                      }
                    : undefined,
            });

            if (resetStore) {
                $ResetPinia().all(); // todo: in testing
            }
        }
    };

    return {
        user,
        loading,
        loggedIn,
        token,
        setToken,
        setUser,
        finishLogin,
        logout,
    };
});

export type AuthStoreType = ReturnType<typeof useAuthStore>;

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
