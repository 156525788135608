<script setup lang="ts">
definePage({
    meta: {
        layout: 'default', // potřeba kvůli potomkům jinak se zanoří layout
    },
});
</script>

<template>
    <app-page padding>
        <app-page-header title="Index" />
    </app-page>
</template>
