<script setup lang="ts">
definePage({
    meta: {
        layout: 'default', // potřeba kvůli potomkům jinak se zanoří layout
    },
    redirect() {
        return {
            name: '/management/devices',
        };
    },
});
</script>
