import { App } from 'vue';
import { orderBy } from 'lodash-es';
import { dump } from '~/utils/debug';
import bootQuasar from '~/boot/quasar';
import bootRouter from '~/boot/router';
import { AppBootItem } from '~/boot/_types';
import bootPinia from '~/boot/pinia';
import bootAuth from '~/boot/auth';

export const appBoot = (app: App<Element>) => {
    const store = bootPinia(app);

    bootAuth(app);

    const router = bootRouter(app);

    bootQuasar(app);

    const plugins = import.meta.glob<AppBootItem>('./plugins/**/boot.ts', {
        eager: true,
        import: 'default',
    });

    orderBy(plugins, 'priority', 'desc').map(async ({ name, boot, priority }) => {
        await boot({
            app,
            router,
            store,
        }).then(() => dump(`[Plugin][${name}] -> Installed`, { name, priority }));
    });
};
