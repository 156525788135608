/* eslint-disable  @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios';

// > AppConfig
export interface ApiConfig extends Pick<AxiosRequestConfig, 'headers' | 'withCredentials' | 'timeout'> {
    baseURL: string;
    endpointUrl?: string;
}

export interface ApiServiceOpts {
    axios?: ApiRequestConfig;
    debug?: boolean;
    notifyOnError?: boolean;
    public?: boolean;
}

// > Axios request custom "config"
export interface ApiRequestConfig
    extends Pick<
        AxiosRequestConfig,
        | 'data'
        | 'baseURL'
        | 'headers'
        | 'responseType'
        | 'cancelToken'
        | 'onDownloadProgress'
        | 'onUploadProgress'
        | 'timeout'
        | 'params'
        | 'signal'
    > {
    endpointUrl?: string;
    transform?: ApiClientResponseTransformer;
    transformMeta?: ApiClientResponseTransformer;
    notify?: boolean;
}

// > Models
export enum HttpStatusCodeExtended {
    CsrfTokenMismatch = 419,
}
export type ApiStatusCode = HttpStatusCode | HttpStatusCodeExtended;

export type ApiGenericValues = Record<string, any>;

export type ApiErrors<T = ApiGenericValues> = Partial<Record<keyof T, string | undefined>> & {
    _reason: string;
};

export interface ApiClientResponseCustom<D, E = unknown, M = unknown> {
    data: D;
    status: ApiStatusCode;
    meta: M;
    message: string;
    isSuccess: boolean;
    isValidationError: boolean;
    isAuthError: boolean;
    isNotFoundError: boolean;
    errors: ApiErrors<E>;
}

export type ApiClientResponse<D = any, E = any, M = any> = ApiClientResponseCustom<D, E, M> & AxiosResponse<D, E>;

export type ApiClientResponseTransformer<T = unknown> = (data: any) => T;
