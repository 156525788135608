import * as rules from '@vee-validate/rules';
//import i18n from '~/plugins/i18n';
import { configure, defineRule } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
//import { FieldValidationMetaInfo } from '@vee-validate/i18n';
import cs from '@vee-validate/i18n/dist/locale/cs.json';
import { AppBootItem } from '~/boot/_types';

export default {
    name: 'veeValidate',
    boot: async () => {
        // Define all @vee-validate/rules
        Object.keys(rules).forEach((rule) => defineRule(rule, rules[rule as never]));
        // i18n message resolver
        configure({
            generateMessage: localize({ cs }),
            /*generateMessage: ({ label, rule, field, name }: FieldValidationMetaInfo) => {
                const fieldName = label || i18n.global.t(`validation.names.${name}`, field);
                const params = [fieldName, ...(rule?.params as unknown[])];

                return i18n.global.t(`validation.fields.${name}.${rule?.name}`, params, {
                    default: i18n.global.t(`validation.messages.${rule?.name}`, params, {
                        default: `[${rule?.name} - missing translation]`,
                    }),
                });
            },*/
        });

        localize({ cs });

        setLocale('cs');
    },
    priority: 5,
} satisfies AppBootItem;
