<script setup lang="ts">
import useMonitoringDeviceStatisticsGraphic from '~/composables/monitoring/device/useMonitoringDeviceStatisticsGraphic';
import useMonitoringDeviceDataPointList from '~/composables/monitoring/device/useMonitoringDeviceDataPointList';
import { MonitoringDataPointData } from '~/api/endpoints/monitoring/data-point/_types';
import { AppDateRangeProps } from '~/components/app/inputs/date-range/_types';
import { DateTime } from 'luxon';
import { QSelect } from 'quasar';

definePage({
    meta: {
        layout: 'default',
    },
});

const { isLoading, data: statisticsData, execute } = useMonitoringDeviceStatisticsGraphic();
const {
    isLoading: dataPointsIsLoading,
    execute: dataPointsExecute,
    data: optionsData,
} = useMonitoringDeviceDataPointList();

const { isMenuLoading } = useMonitoring();

onBeforeMount(() => {
    const today = DateTime.now().set({ minute: 0, second: 0, millisecond: 0 });

    dateRange.value = {
        from: today.set({ hour: 0, minute: 0 }),
        to: today.set({ hour: 23, minute: 0 }),
    };
});

const rangeTypeOptions = [
    {
        Label: 'Hodinový',
        Value: 'Hourly',
    },
    {
        Label: 'Denní',
        Value: 'Daily',
    },
];

const mounted = ref(false);
const tab = ref('configurator');
const dataPointSelectRef = ref<QSelect>();
const dataPoint = ref<MonitoringDataPointData[]>();
const dateRange = ref<AppDateRangeProps['modelValue']>();
const rangeType = ref(rangeTypeOptions[0]);

watchEffect(() => {
    const range = dateRange.value?.to?.diff(dateRange.value?.from, ['days']);

    if ((range?.days || 0) > 2) {
        rangeType.value = rangeTypeOptions[1];
    } else {
        rangeType.value = rangeTypeOptions[0];
    }
});

const requestQuery = computed(() => {
    return {
        From: dateRange.value?.from?.setZone('UTC', { keepLocalTime: true }),
        To: dateRange.value?.to?.setZone('UTC', { keepLocalTime: true }),
        DataPointIds: dataPoint.value?.map((d) => d.Id),
        Type: rangeType.value?.Value,
    };
});

onBeforeMount(async () => {
    await dataPointsExecute().then((r) => {
        if (r.data && r.data.length > 0) {
            dataPoint.value = [r.data[0]];
        }
    });
    await execute(undefined, requestQuery.value);

    mounted.value = true;
});

watch(
    () => requestQuery.value,
    (value) => {
        if (!mounted.value) return;
        execute(undefined, value);
    },
    { deep: true }
);
</script>

<template>
    <app-page padding :loading="isMenuLoading">
        <app-page-header title="Statistiky - Grafické" />

        <div class="row q-col-gutter-sm q-col-gutter-sm-md q-col-gutter-lg-lg">
            <div class="col-all">
                <app-card>
                    <template #title>
                        <q-tabs v-model="tab" inline-label dense align="left">
                            <q-tab name="configurator" icon="las la-cog" label="Konfigurátor" />
                            <q-tab disable name="stored" icon="las la-save" label="Uložené" />
                        </q-tabs>
                    </template>
                    <q-tab-panels v-model="tab" vertical>
                        <q-tab-panel name="configurator">
                            <q-card-section class="row items-start q-col-gutter-sm q-py-sm q-pa-none">
                                <div class="col-12 col-sm-6 col-lg-2">
                                    <q-select
                                        ref="dataPointSelectRef"
                                        outlined
                                        dense
                                        v-model="dataPoint"
                                        :options="optionsData || undefined"
                                        option-label="Name"
                                        option-value="Id"
                                        label="Datapoint"
                                        multiple
                                        use-chips
                                        :loading="dataPointsIsLoading"
                                        @add="() => dataPointSelectRef?.hidePopup()"
                                        @remove="() => dataPointSelectRef?.hidePopup()"
                                    />
                                </div>
                                <div class="col-12 col-sm-6 col-lg-2 col-xl-1">
                                    <q-select
                                        outlined
                                        dense
                                        v-model="rangeType"
                                        :options="rangeTypeOptions"
                                        option-label="Label"
                                        option-value="Value"
                                        label="Typ"
                                    />
                                </div>

                                <div class="col-12 col-sm-6 col-lg-auto">
                                    <app-date-range
                                        v-model="dateRange"
                                        label="Časové období"
                                        :with-time="rangeType.Value === 'Hourly'"
                                    />
                                </div>
                            </q-card-section>
                        </q-tab-panel>
                    </q-tab-panels>
                </app-card>
            </div>

            <div class="col-all" v-for="dataPointData in statisticsData?.DataPoints" :key="dataPointData.Id">
                <monitoring-device-card-statistic-graphic :loading="isLoading" :data="dataPointData" />
            </div>
        </div>
    </app-page>
</template>
