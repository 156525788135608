import { ApiStatusCode, HttpStatusCodeExtended } from '~/boot/plugins/apiClient/_types';
import { HttpStatusCode } from 'axios';

export const apiClientAuthErrors: ApiStatusCode[] = [
    HttpStatusCodeExtended.CsrfTokenMismatch,
    HttpStatusCode.Unauthorized,
];

export const apiClientReportableErrors: ApiStatusCode[] = [
    HttpStatusCode.Forbidden,
    HttpStatusCode.NotFound,
    HttpStatusCode.MethodNotAllowed,
    HttpStatusCode.TooManyRequests,
    HttpStatusCode.ServiceUnavailable,
    HttpStatusCode.InternalServerError,
];
