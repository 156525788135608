import { AuthUser } from '~/boot/auth/_types';
import { ManagementUserListData } from '~/api/endpoints/management/users/_types';
import { MonitoringDeviceListData } from '~/api/endpoints/monitoring/devices/_types';

const useMonitoringStore = defineStore('monitoring', () => {
    const activeUser = ref<AuthUser | ManagementUserListData | null>(null);
    const activeDevice = ref<MonitoringDeviceListData | null>(null);
    const isMenuLoading = ref<boolean>(false);

    return {
        activeUser,
        activeDevice: activeDevice,
        isMenuLoading,
    };
});

export default useMonitoringStore;
