/* eslint-disable  @typescript-eslint/no-explicit-any */
import { pick } from 'lodash-es';
import { ManagementUserListData, RoleListData, UserData } from '~/api/endpoints/management/users/_types';

export const rawToManagementUserListData = (data: any): ManagementUserListData | null => {
    if (data == null) return null;
    return {
        ...pick(data, [
            'Id',
            'FirstName',
            'LastName',
            'Login',
            'IsAdmin',
            'Email',
            'Phone',
            'IsActive',
            'OwnedDevicesCount',
        ]),
        FullName: `${data.FirstName} ${data.LastName}`,
        ApplicationRoles: rawToRoleListArray(data.ApplicationRoles),
    };
};

export const rawToRoleListData = (data: any): RoleListData | null => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Type', 'Key', 'Name']),
    };
};

export const rawToRoleListArray = (data: any): RoleListData[] | null => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToRoleListData(x)).filter((x): x is RoleListData => !!x);
};

export const rawToManagementUserListArray = (data: any): ManagementUserListData[] | null => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToManagementUserListData(x)).filter((x): x is ManagementUserListData => !!x);
};

export const rawToUserData = (data: any): UserData | null => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'FirstName', 'LastName', 'Email', 'Phone', 'IsActive']),
        FullName: `${data.FirstName} ${data.LastName}`,
    };
};

export const rawToUserDataArray = (data: any): UserData[] | null => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToUserData(x)).filter((x): x is UserData => !!x);
};
