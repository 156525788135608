import { ParseEnvValueFn } from './_types';
import { isString } from 'lodash-es';

/**
 * Parse ENV variable value
 *
 * @param value
 * @param fallbackValue
 */
export const parseEnvValue: ParseEnvValueFn = <T>(value: unknown, fallbackValue?: T) => {
    const parsedValue = isString(value) && value.trim().length !== 0 ? (value as T) : undefined;
    return parsedValue || fallbackValue;
};

/**
 * Parse ENV variable value, throw Error if the value is missing / empty
 *
 * @param value
 */
export const parseEnvValueOrFail = <T>(value: unknown): T => {
    const parsedValue = parseEnvValue<T>(value);

    if (typeof parsedValue === 'undefined') {
        throw Error('Missing required env variable!');
    }

    return parsedValue;
};
