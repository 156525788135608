<script setup lang="ts">
definePage({
    meta: {
        layout: 'default',
    },
});
</script>

<template>
    <core-page-auth-profile />
</template>
