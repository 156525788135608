import { AxiosInstance, AxiosRequestConfig, Method } from 'axios';
import { ApiClientResponse, ApiRequestConfig, ApiServiceOpts } from '~/boot/plugins/apiClient/_types';
import { apiClientTransformResponseData } from '~/boot/plugins/apiClient/_response';
import { Notify } from 'quasar';
import { apiClientReportableErrors } from '~/boot/plugins/apiClient/_config';
import { hasFiles, normalizeUrl } from '~/utils/base';
import appConfig from '~/boot/plugins/config';
import { serialize } from 'object-to-formdata';

export const useApiClientRequests = (axiosInstance: AxiosInstance, serviceOpts?: ApiServiceOpts) => {
    const request = async <T = unknown, D = unknown, M = unknown>(
        url: string,
        method: Method,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiClientResponse<T, D>> => {
        const baseUrl = normalizeUrl(requestConfig?.baseURL || appConfig.api.baseURL);
        const axiosConfig: AxiosRequestConfig = {
            ...requestConfig,
            url,
            method,
        };

        if (requestConfig?.endpointUrl) {
            axiosConfig.baseURL = `${baseUrl}${requestConfig.endpointUrl}`;
        }

        if (hasFiles(axiosConfig.data)) {
            axiosConfig.transformRequest = [(data) => serialize(data)];
        }

        const response = await axiosInstance.request<T, ApiClientResponse<T>, D>(axiosConfig);

        if (
            apiClientReportableErrors.includes(response.status) &&
            !!serviceOpts?.notifyOnError &&
            !(requestConfig?.notify === false)
        ) {
            Notify.create({
                group: 'api:client',
                type: 'negative',
                message: 'API chyba',
                caption: response.errors._reason,
                actions: [{ icon: 'las la-times', color: 'white' }],
                timeout: 10000,
            });
        }

        return {
            ...response,
            data: apiClientTransformResponseData<T>(response.data, requestConfig?.transform),
            meta: apiClientTransformResponseData<M>(response.meta, requestConfig?.transformMeta),
        };
    };

    const getRequest = async <T = unknown, D = unknown>(
        url: string,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiClientResponse<T, D>> => {
        return request<T, D>(url, 'get', requestConfig);
    };

    const postRequest = async <T = unknown, D = unknown>(
        url: string,
        data?: unknown,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiClientResponse<T, D>> => {
        requestConfig = { ...requestConfig, data };
        return request<T, D>(url, 'post', requestConfig);
    };

    const putRequest = async <T = unknown, D = unknown>(
        url: string,
        data?: unknown,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiClientResponse<T, D>> => {
        requestConfig = { ...requestConfig, data };
        return request<T, D>(url, 'put', requestConfig);
    };

    const patchRequest = async <T = unknown, D = unknown>(
        url: string,
        data?: unknown,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiClientResponse<T>> => {
        requestConfig = { ...requestConfig, data };
        return request<T, D>(url, 'patch', requestConfig);
    };

    const deleteRequest = async <T = unknown, D = unknown>(
        url: string,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiClientResponse<T>> => {
        return request<T, D>(url, 'delete', requestConfig);
    };

    return {
        request,
        get: getRequest,
        post: postRequest,
        put: putRequest,
        patch: patchRequest,
        delete: deleteRequest,
    };
};
