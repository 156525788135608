import { ApiRequestConfig, ApiServiceOpts } from './_types';
import axios, { AxiosInstance } from 'axios';
import { normalizeUrl } from '~/utils/base';
import { merge } from 'lodash-es';
import { dump } from '~/utils/debug';
import appConfig from '~/boot/plugins/config';
import { useApiClientInterceptors } from '~/boot/plugins/apiClient/_interceptors';
import { useApiClientRequests } from '~/boot/plugins/apiClient/_requests';

const apiClient = (endpointUrl: string, serviceOpts?: ApiServiceOpts) => {
    const _baseUrl: string = normalizeUrl(serviceOpts?.axios?.baseURL || appConfig.api.baseURL);
    const _defaultConfig: ApiRequestConfig = merge(serviceOpts?.axios || { ...appConfig.api }, {
        baseURL: `${_baseUrl}${endpointUrl}`,
    });
    const _axiosInstance: AxiosInstance = axios.create(_defaultConfig);

    const interceptors = useApiClientInterceptors(serviceOpts);

    _axiosInstance.interceptors.request.use(interceptors.requestInterceptor);
    _axiosInstance.interceptors.response.use(interceptors.responseInterceptor, interceptors.responseErrorInterceptor);

    const requests = useApiClientRequests(_axiosInstance, serviceOpts);

    dump('[apiClient]', { endpointUrl, serviceOpts });

    return {
        ...requests,
    };
};

export default apiClient;
