<script setup lang="ts">
import useMonitoringDeviceDashboard from '~/composables/monitoring/device/useMonitoringDeviceDashboard';

definePage({
    meta: {
        layout: 'default', // potřeba kvůli potomkům jinak se zanoří layout
    },
});

const { activeDevice } = useMonitoring();

const { data: deviceDashboardData, isLoading } = useMonitoringDeviceDashboard();
</script>

<template>
    <app-page padding>
        <app-page-header :title="`Zařízení - ${activeDevice?.Name}`" />

        <div class="row q-col-gutter-sm q-col-gutter-sm-md q-col-gutter-lg-lg">
            <div class="col-12">
                <div class="row q-col-gutter-sm q-col-gutter-sm-md q-col-gutter-lg-lg justify-center">
                    <div class="col-12 col-sm-3">
                        <monitoring-device-card-dashboard-production
                            class="fit"
                            :device-dashboard-data="deviceDashboardData"
                            :loading="isLoading"
                        />
                    </div>
                    <div class="col-12 col-sm-3">
                        <monitoring-device-card-dashboard-consumption
                            class="fit"
                            :device-dashboard-data="deviceDashboardData"
                            :loading="isLoading"
                        />
                    </div>
                    <div class="col-12 col-sm-3">
                        <monitoring-device-card-dashboard-battery
                            class="fit"
                            :device-dashboard-data="deviceDashboardData"
                            :loading="isLoading"
                        />
                    </div>
                    <div class="col-12 col-sm-3">
                        <monitoring-device-card-dashboard-distribution
                            class="fit"
                            :device-dashboard-data="deviceDashboardData"
                            :loading="isLoading"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <monitoring-device-card-dashboard-energy-sources
                    class="fit"
                    :device-dashboard-data="deviceDashboardData"
                    :loading="isLoading"
                />
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
                <monitoring-device-card-dashboard-data-fve
                    class="fit"
                    :device-dashboard-data="deviceDashboardData"
                    :loading="isLoading"
                />
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
                <monitoring-device-card-dashboard-digi-box-info
                    class="fit"
                    :device-dashboard-data="deviceDashboardData"
                    :loading="isLoading"
                />
            </div>
        </div>
    </app-page>
</template>
