import { DataLoaderPlugin } from 'unplugin-vue-router/runtime';
import { App } from 'vue';
import appCreateRouter from '~/boot/router/router';
import { dump } from '~/utils/debug';

export const routerInstance = appCreateRouter();

const bootRouter = (app: App) => {
    // https://uvr.esm.is/rfcs/data-loaders/#data-loader-setup
    // https://uvr.esm.is/rfcs/data-loaders/#implementations
    app.use(DataLoaderPlugin, { router: routerInstance });

    app.use(routerInstance);

    dump('[Router] -> Booted');

    return routerInstance;
};

export default bootRouter;
