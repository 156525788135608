<script lang="ts">
export { useMonitoringDeviceWattRouterRelaysLoader } from '~/loaders/useMonitoringDeviceWattRouterRelaysLoader';
export { useMonitoringDeviceSettingsWallboxLoader } from '~/loaders/useMonitoringDeviceSettingsWallboxLoader';
</script>

<script setup lang="ts">
import { useMonitoringDeviceWattRouterRelaysLoader } from '~/loaders/useMonitoringDeviceWattRouterRelaysLoader';
import { useMonitoringDeviceSettingsWallboxLoader } from '~/loaders/useMonitoringDeviceSettingsWallboxLoader';
import { delay } from 'lodash';

definePage({
    meta: {
        layout: 'default',
    },
});
const { isMenuLoading } = useMonitoring();

const { isLoading, data } = useMonitoringDeviceWattRouterRelaysLoader();
const { isLoading: wallBoxIsLoading, data: wallBoxData } = useMonitoringDeviceSettingsWallboxLoader();

const loadWattRouterValuesControlData = ref({ isLoading: false });
const restartControlData = ref({ isLoading: false });

const onClickLoadWattrouterValuesControlBtn = () => {
    loadWattRouterValuesControlData.value = {
        isLoading: true,
    };

    delay(() => {
        loadWattRouterValuesControlData.value = {
            isLoading: false,
        };
    }, 1000); // every 1 second
};

const onClickRestartControlBtn = () => {
    restartControlData.value = {
        isLoading: true,
    };

    delay(() => {
        restartControlData.value = {
            isLoading: false,
        };
    }, 1000); // every 1 second
};
</script>

<template>
    <app-page padding :loading="isMenuLoading || (isLoading && !data) || (wallBoxIsLoading && !wallBoxData)">
        <app-page-header title="Ovládání - Funkce" />

        <div class="row q-col-gutter-lg justify-center">
            <div class="col-12 col-lg-10 col-xl-6">
                <div class="row q-col-gutter-lg justify-center">
                    <div class="col-12">
                        <app-card title="DigiBOX">
                            <div class="text-grey-8 q-gutter-md q-pa-md">
                                <app-btn
                                    color="primary"
                                    :loading="loadWattRouterValuesControlData.isLoading"
                                    label="Načíst hodnoty Wattrouteru"
                                    @click="onClickLoadWattrouterValuesControlBtn"
                                />
                                <app-btn
                                    color="warning"
                                    text-color="on-warning"
                                    :loading="restartControlData.isLoading"
                                    label="Restartovat"
                                    @click="onClickRestartControlBtn"
                                />
                            </div>
                        </app-card>
                    </div>
                    <div class="col-12">
                        <monitoring-device-wattrouter-card-control />
                    </div>
                    <div class="col-12">
                        <monitoring-device-wallbox-card-control />
                    </div>
                </div>
            </div>
        </div>
    </app-page>
</template>
