<script setup lang="ts">
definePage({
    meta: {
        layout: 'auth', // potřeba kvůli potomkům jinak se zanoří layout
    },
    redirect() {
        return {
            name: '/auth/login',
        };
    },
});
</script>
