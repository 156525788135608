<script setup lang="ts">
definePage({
    meta: {
        layout: 'default', // potřeba kvůli potomkům jinak se zanoří layout
    },
});
const { activeDevice: activeDevice } = useMonitoring();
</script>

<template>
    <app-page padding>
        <app-page-header :title="`Zařízení - ${activeDevice?.Name}`" />
        <monitoring-device-debug />
    </app-page>
</template>
