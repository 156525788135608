import { Notify } from 'quasar';
import { useAuthStore } from '~/boot/auth/store';
import { AuthUser } from '~/boot/auth/_types';
import { fetchUserRequest, loginRequest, logoutRequest } from '~/api/auth/requests';
import { AuthLoginFormData } from '~/api/auth/_types';
import { isNull } from 'lodash-es';
import { SubmissionContext } from 'vee-validate';

export const logout = async (silent = false): Promise<void> => {
    const authStore = useAuthStore();

    await logoutRequest();
    await authStore.logout();

    if (!silent) {
        Notify.create({
            group: 'loggedOut',
            type: 'positive',
            message: 'Byl jste odhlášen',
        });
    }
};

export const fetchUser = async (): Promise<boolean> => {
    const authStore = useAuthStore();
    const { data, isSuccess } = await fetchUserRequest();

    if (isSuccess) {
        authStore.setUser(data);
    }

    return isSuccess;
};

export const autoLogin = async (): Promise<boolean> => {
    const authStore = useAuthStore();

    if (authStore.loggedIn || !authStore.token?.Validated) {
        return false;
    }

    return await fetchUser();
};

const finishLogin = async (data: AuthUser) => {
    const authStore = useAuthStore();

    await authStore.finishLogin(data);

    Notify.create({
        group: 'loggedIn',
        type: 'positive',
        message: 'Byl jste přihlášen',
    });
};

export const login = async (formData: AuthLoginFormData, ctx: SubmissionContext<AuthLoginFormData>) => {
    const authStore = useAuthStore();

    const { data, isSuccess: isSuccessLoginRequest, isValidationError, errors } = await loginRequest(formData);

    if (!isSuccessLoginRequest || isNull(data)) {
        ctx.setErrors(isValidationError ? errors : { Password: 'Přihlašovací údaje nejsou platné', Login: ' ' });
        return;
    }

    authStore.setToken({ ...data.Token, Validated: false });

    if (data.User) {
        await finishLogin(data.User);
    }
};
