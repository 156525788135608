import { createApp } from 'vue';
import App from '~/App.vue';
import { appBoot } from '~/boot';

// App init
const app = createApp(App);

// App boot
appBoot(app);

// Mount app
app.mount('#app');
