import _definePage_default_0 from '/spa/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/spa/src/pages/auth/index.vue?definePage&vue'
import _definePage_default_3 from '/spa/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_4 from '/spa/src/pages/auth/profile.vue?definePage&vue'
import _definePage_default_5 from '/spa/src/pages/devices/index.vue?definePage&vue'
import _definePage_default_6 from '/spa/src/pages/devices/[deviceId]/index.vue?definePage&vue'
import _definePage_default_7 from '/spa/src/pages/devices/[deviceId]/control/index.vue?definePage&vue'
import _definePage_default_8 from '/spa/src/pages/devices/[deviceId]/control/functions.vue?definePage&vue'
import _definePage_default_9 from '/spa/src/pages/devices/[deviceId]/dashboard-2.vue?definePage&vue'
import _definePage_default_10 from '/spa/src/pages/devices/[deviceId]/fve/index.vue?definePage&vue'
import _definePage_default_11 from '/spa/src/pages/devices/[deviceId]/fve/info.vue?definePage&vue'
import _definePage_default_12 from '/spa/src/pages/devices/[deviceId]/settings/index.vue?definePage&vue'
import _definePage_default_13 from '/spa/src/pages/devices/[deviceId]/settings/grid-export.vue?definePage&vue'
import _definePage_default_14 from '/spa/src/pages/devices/[deviceId]/settings/system.vue?definePage&vue'
import _definePage_default_15 from '/spa/src/pages/devices/[deviceId]/settings/wallbox.vue?definePage&vue'
import _definePage_default_16 from '/spa/src/pages/devices/[deviceId]/settings/wattrouter.vue?definePage&vue'
import _definePage_default_17 from '/spa/src/pages/devices/[deviceId]/statistics/index.vue?definePage&vue'
import _definePage_default_18 from '/spa/src/pages/devices/[deviceId]/statistics/graphic.vue?definePage&vue'
import _definePage_default_19 from '/spa/src/pages/management/index.vue?definePage&vue'
import _definePage_default_20 from '/spa/src/pages/management/devices.vue?definePage&vue'
import _definePage_default_21 from '/spa/src/pages/management/hardware.vue?definePage&vue'
import _definePage_default_22 from '/spa/src/pages/management/users.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/spa/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/auth',
    /* internal name: '/auth' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/auth/',
        component: () => import('/spa/src/pages/auth/index.vue'),
        /* no children */
      },
  _definePage_default_2
  ),
  _mergeRouteRecord(
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/spa/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'profile',
        name: '/auth/profile',
        component: () => import('/spa/src/pages/auth/profile.vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  {
    path: '/devices',
    /* internal name: '/devices' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/devices/',
        component: () => import('/spa/src/pages/devices/index.vue'),
        /* no children */
      },
  _definePage_default_5
  ),
      {
        path: ':deviceId',
        /* internal name: '/devices/[deviceId]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/devices/[deviceId]/',
            component: () => import('/spa/src/pages/devices/[deviceId]/index.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
          {
            path: 'control',
            /* internal name: '/devices/[deviceId]/control' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/devices/[deviceId]/control/',
                component: () => import('/spa/src/pages/devices/[deviceId]/control/index.vue'),
                /* no children */
              },
  _definePage_default_7
  ),
  _mergeRouteRecord(
              {
                path: 'functions',
                name: '/devices/[deviceId]/control/functions',
                component: () => import('/spa/src/pages/devices/[deviceId]/control/functions.vue'),
                /* no children */
              },
  _definePage_default_8
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'dashboard-2',
            name: '/devices/[deviceId]/dashboard-2',
            component: () => import('/spa/src/pages/devices/[deviceId]/dashboard-2.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
          {
            path: 'fve',
            /* internal name: '/devices/[deviceId]/fve' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/devices/[deviceId]/fve/',
                component: () => import('/spa/src/pages/devices/[deviceId]/fve/index.vue'),
                /* no children */
              },
  _definePage_default_10
  ),
  _mergeRouteRecord(
              {
                path: 'info',
                name: '/devices/[deviceId]/fve/info',
                component: () => import('/spa/src/pages/devices/[deviceId]/fve/info.vue'),
                /* no children */
              },
  _definePage_default_11
  )
            ],
          },
          {
            path: 'settings',
            /* internal name: '/devices/[deviceId]/settings' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/devices/[deviceId]/settings/',
                component: () => import('/spa/src/pages/devices/[deviceId]/settings/index.vue'),
                /* no children */
              },
  _definePage_default_12
  ),
  _mergeRouteRecord(
              {
                path: 'grid-export',
                name: '/devices/[deviceId]/settings/grid-export',
                component: () => import('/spa/src/pages/devices/[deviceId]/settings/grid-export.vue'),
                /* no children */
              },
  _definePage_default_13
  ),
  _mergeRouteRecord(
              {
                path: 'system',
                name: '/devices/[deviceId]/settings/system',
                component: () => import('/spa/src/pages/devices/[deviceId]/settings/system.vue'),
                /* no children */
              },
  _definePage_default_14
  ),
  _mergeRouteRecord(
              {
                path: 'wallbox',
                name: '/devices/[deviceId]/settings/wallbox',
                component: () => import('/spa/src/pages/devices/[deviceId]/settings/wallbox.vue'),
                /* no children */
              },
  _definePage_default_15
  ),
  _mergeRouteRecord(
              {
                path: 'wattrouter',
                name: '/devices/[deviceId]/settings/wattrouter',
                component: () => import('/spa/src/pages/devices/[deviceId]/settings/wattrouter.vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          },
          {
            path: 'statistics',
            /* internal name: '/devices/[deviceId]/statistics' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/devices/[deviceId]/statistics/',
                component: () => import('/spa/src/pages/devices/[deviceId]/statistics/index.vue'),
                /* no children */
              },
  _definePage_default_17
  ),
  _mergeRouteRecord(
              {
                path: 'graphic',
                name: '/devices/[deviceId]/statistics/graphic',
                component: () => import('/spa/src/pages/devices/[deviceId]/statistics/graphic.vue'),
                /* no children */
              },
  _definePage_default_18
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/management',
    /* internal name: '/management' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/management/',
        component: () => import('/spa/src/pages/management/index.vue'),
        /* no children */
      },
  _definePage_default_19
  ),
  _mergeRouteRecord(
      {
        path: 'devices',
        name: '/management/devices',
        component: () => import('/spa/src/pages/management/devices.vue'),
        /* no children */
      },
  _definePage_default_20
  ),
  _mergeRouteRecord(
      {
        path: 'hardware',
        name: '/management/hardware',
        component: () => import('/spa/src/pages/management/hardware.vue'),
        /* no children */
      },
  _definePage_default_21
  ),
  _mergeRouteRecord(
      {
        path: 'users',
        name: '/management/users',
        component: () => import('/spa/src/pages/management/users.vue'),
        /* no children */
      },
  _definePage_default_22
  )
    ],
  }
]
