import { AppBootItem } from '~/boot/_types';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { useAuthStore } from '~/boot/auth/store';

declare global {
    interface Window {
        Pusher: typeof Pusher;
        Echo: Echo;
    }
}

export default {
    name: 'laravel-echo',
    boot: async () => {
        const authStore = useAuthStore();

        window.Pusher = Pusher;

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: import.meta.env.VITE_PUSHER_APP_KEY,
            wsHost: import.meta.env.VITE_PUSHER_HOST,
            wsPort: import.meta.env.VITE_PUSHER_PORT || 80,
            wssPort: import.meta.env.VITE_PUSHER_PORT || 443,
            forceTLS: (import.meta.env.VITE_PUSHER_SCHEME || 'https') === 'https',
            enabledTransports: ['ws', 'wss'],
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
            bearerToken: authStore.token?.Value,
            authEndpoint: import.meta.env.VITE_API_URL + '/broadcasting/auth',
        });
    },
    priority: 10,
} satisfies AppBootItem;
