<script lang="ts">
export { useMonitoringDeviceSettingsGridExportLoader } from '~/loaders/useMonitoringDeviceSettingsGridExportLoader';
</script>

<script setup lang="ts">
import { useMonitoringDeviceSettingsGridExportLoader } from '~/loaders/useMonitoringDeviceSettingsGridExportLoader';

definePage({
    meta: {
        layout: 'default',
    },
});

const { isMenuLoading } = useMonitoring();

const { isLoading, data } = useMonitoringDeviceSettingsGridExportLoader();
</script>

<template>
    <app-page padding :loading="isMenuLoading || (isLoading && !data)">
        <app-page-header title="Nastavení - Dodávka do sítě" />
        <div class="row q-col-gutter-lg justify-center">
            <div class="col-auto column q-col-gutter-lg justify-center">
                <div class="col-auto">
                    <monitoring-form-settings-grid-export />
                </div>
            </div>
        </div>
    </app-page>
</template>
