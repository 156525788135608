import { NavigationHookAfter, START_LOCATION } from 'vue-router';
import { NavigationGuardWithThis } from 'unplugin-vue-router/types';
import { autoLogin } from '~/api/auth/services';
import { useAuthStore } from '~/boot/auth/store';

export const beforeEach: NavigationGuardWithThis<undefined> = async (to, from, next) => {
    if (from === START_LOCATION) {
        await autoLogin();
    }

    const authStore = useAuthStore();

    if (to.name !== '/auth/login' && !authStore.loggedIn) {
        await authStore.logout(false, from !== START_LOCATION);
        return next({ name: '/auth/login' });
    } else if (to.name === '/auth/login' && authStore.loggedIn) {
        return next({ name: '/' });
    }

    next();
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const afterEach: NavigationHookAfter = async (to, from, failure) => {
    //
};
