import { AppConfigSymbol } from '~/boot/plugins/config/_symbols';
import { AppBootItem } from '~/boot/_types';
import appConfig from '~/boot/plugins/config/index';
import { AppConfig } from '~/boot/plugins/config/_types';

export default {
    name: 'appConfig',
    boot: async ({ app }) => {
        app.provide<AppConfig>(AppConfigSymbol, appConfig);
    },
    priority: 100,
} satisfies AppBootItem;
