<script lang="ts">
export { useMonitoringDeviceSettingsWallboxLoader } from '~/loaders/useMonitoringDeviceSettingsWallboxLoader';
</script>

<script setup lang="ts">
import { useMonitoringDeviceSettingsWallboxLoader } from '~/loaders/useMonitoringDeviceSettingsWallboxLoader';
definePage({
    meta: {
        layout: 'default',
    },
});

const { isMenuLoading } = useMonitoring();

const { isLoading, data } = useMonitoringDeviceSettingsWallboxLoader();
</script>

<template>
    <app-page padding :loading="isMenuLoading || (isLoading && !data)">
        <app-page-header title="Nastavení - Wallbox" />
        <div class="row q-col-gutter-lg justify-center">
            <div class="col-auto column q-col-gutter-lg justify-center">
                <div class="col-auto">
                    <monitoring-form-settings-wallbox />
                </div>
            </div>
        </div>
    </app-page>
</template>
