import { AxiosResponse, HttpStatusCode } from 'axios';
import { ApiErrors, ApiClientResponse, ApiGenericValues, ApiClientResponseTransformer } from './_types';
import { isNull, isUndefined } from 'lodash-es';

const _isErrorBagObject = (data: unknown): data is ApiGenericValues => {
    return data !== null && typeof data === 'object';
};

const _isLaravelErrorBagObject = (data: unknown): data is { errors: ApiGenericValues } => {
    return _isErrorBagObject(data) && 'errors' in data;
};

const _parseErrors = ({ isValidationError, data, message }: ApiClientResponse): ApiErrors => {
    // Custom error bag response ("errors" -> "Data") { F1: [...], F2: [...]  } and HTTP status = UnprocessableEntity
    if (isValidationError && _isErrorBagObject(data)) {
        return { ...data, _reason: 'Validation error' };
    }
    // Laravel error bag response { errors: { F1: [...], F2: [...] } and HTTP status = BadRequest or something else
    if (_isLaravelErrorBagObject(data)) {
        return { ...data.errors, _reason: 'Validation Error' };
    }
    // "message"
    if (typeof data === 'string') {
        return { _reason: data };
    }

    return { _reason: message };
};

export const apiClientParseRawResponse = async <T = unknown>(
    rawResponse: AxiosResponse
): Promise<ApiClientResponse<T>> => {
    const baseResponse: AxiosResponse = {
        ...rawResponse,
        status: rawResponse.status || HttpStatusCode.ServiceUnavailable,
        statusText: rawResponse.statusText || 'Unknown response',
    };

    const isSuccess = baseResponse.status >= 200 && baseResponse.status < 300;
    const isValidationError = [HttpStatusCode.UnprocessableEntity].includes(baseResponse.status);
    const isAuthError = [HttpStatusCode.Unauthorized].includes(baseResponse.status);
    const isNotFoundError = baseResponse.status === HttpStatusCode.NotFound;

    let parsedData = baseResponse.data;
    try {
        if (baseResponse.config?.responseType === 'blob' && !isSuccess) {
            parsedData = JSON.parse(await baseResponse.data.text());
        }
    } catch (e) {
        // Exception
    }

    const response: ApiClientResponse = {
        ...baseResponse,
        data: parsedData?.Data ?? (parsedData || null),
        meta: parsedData?.Meta || null,
        message:
            (parsedData?.message ?? parsedData?.ResponseInfo?.Message) ||
            (!isSuccess ? 'There was a problem processing the request.' : ''),
        isSuccess,
        isValidationError,
        isAuthError,
        isNotFoundError,
        errors: { _reason: '< ? >' },
    };

    if (!isSuccess) {
        response.errors = _parseErrors(response);
        response.data = null;
    }

    return response;
};

export const apiClientTransformResponseData = <T>(data: T, transformer?: ApiClientResponseTransformer): T => {
    if (!isUndefined(transformer) && !isNull(data)) {
        return transformer(data) as T;
    }

    return data;
};
