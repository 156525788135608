import apiClientInstance from '~/boot/plugins/apiClient';
import { AuthData, AuthUser } from '~/boot/auth/_types';
import { ApiClientResponseCustom } from '~/boot/plugins/apiClient/_types';
import { AuthLoginFormData } from '~/api/auth/_types';
import { rawToAuthData, rawToAuthUser } from '~/api/auth/_transformers';

/**
 * @param values
 */
export const loginRequest = async (
    values: AuthLoginFormData
): Promise<ApiClientResponseCustom<AuthData | null, AuthLoginFormData>> => {
    return apiClientInstance.post('auth/login', values, {
        transform: rawToAuthData,
    });
};

export const logoutRequest = async () => {
    return apiClientInstance.post('auth/logout');
};

export const fetchUserRequest = async () => {
    return apiClientInstance.get<AuthUser | null>('auth/info', {
        transform: rawToAuthUser,
    });
};
