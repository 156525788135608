import { routes } from 'vue-router/auto-routes'
import { createRouter as _createRouter } from 'vue-router'

export * from 'vue-router'
export {
  definePage,
  // new data fetching
  DataLoaderPlugin,
  NavigationResult,
} from 'unplugin-vue-router/runtime'

export * from 'unplugin-vue-router/data-loaders/basic'
export * from 'unplugin-vue-router/data-loaders/pinia-colada'

export function createRouter(options) {
  const { extendRoutes } = options
  // use Object.assign for better browser support
  const router = _createRouter(Object.assign(
    options,
    { routes: typeof extendRoutes === 'function' ? extendRoutes(routes) : routes },
  ))

  return router
}
