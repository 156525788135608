import { Router } from 'vue-router';
import { App } from 'vue';
import { createPinia } from 'pinia';
import { dump } from '~/utils/debug';
import { routerInstance } from '~/boot/router';
import { QueryPlugin } from '@pinia/colada';
import { cloneDeep } from 'lodash';

export const useDirectRouter = () => getActivePinia()?.router() as Router;

// https://github.com/vuejs/pinia/discussions/911#discussioncomment-3516647
export const $ResetPinia = (): Record<string | 'all', () => void> => {
    const pinia = getActivePinia();

    if (!pinia) {
        throw new Error('There is no stores');
    }

    const resetStores: Record<string, () => void> = {};

    pinia._s.forEach((store, name) => {
        resetStores[name] = () => store.$reset();
    });

    resetStores.all = () => pinia._s.forEach((store) => store.$reset());
    return resetStores;
};

// @ts-ignore
const bootPinia = (app: App) => {
    const pinia = createPinia();

    pinia.router = () => routerInstance;

    pinia.use(({ store }) => {
        const initialState = cloneDeep(store.$state);

        store.$reset = () => {
            store.$patch(($state) => {
                Object.assign($state, initialState);
            });
        };
    });
    app.use(pinia);

    // https://uvr.esm.is/rfcs/data-loaders/colada.html
    app.use(QueryPlugin, {
        // optional options
    });

    dump('[Pinia] -> Booted');

    return pinia;
};

export default bootPinia;
