import { App } from 'vue';
import { dump } from '~/utils/debug';
import { useAuthStore } from '~/boot/auth/store';
import { AuthKey } from '~/boot/auth/_symbols';

const bootAuth = (app: App) => {
    const authStore = useAuthStore();

    app.config.globalProperties.$auth = authStore;
    app.provide(AuthKey, authStore);

    dump('[Auth] -> Booted');
};

export default bootAuth;
