import useMonitoringStore from '~/stores/monitoring';
import { LocalStorage } from 'quasar';
import { MonitoringDeviceListData } from '~/api/endpoints/monitoring/devices/_types';
import { AuthUser } from '~/boot/auth/_types';
import { ManagementUserListData } from '~/api/endpoints/management/users/_types';
import { useQueryCache } from '@pinia/colada';

const useMonitoring = () => {
    const store = storeToRefs(useMonitoringStore());
    const storageUserId = computed<string | undefined>(
        () => LocalStorage.getItem<string>('monitoring_last_user_id') || undefined
    );
    const storageDeviceId = computed(() => LocalStorage.getItem<string>('monitoring_last_device_id'));

    const setIsMenuLoading = (val: boolean = true) => {
        store.isMenuLoading.value = val;
    };

    const setActiveUser = (user?: AuthUser | ManagementUserListData | null, clearDevice: boolean = true) => {
        if (store.activeUser.value?.Id !== user?.Id) {
            useQueryCache().$reset();
        }

        store.activeUser.value = user || null;
        LocalStorage.set('monitoring_last_user_id', user?.Id || '');

        if (clearDevice) {
            setActiveDevice(undefined);
        }
    };

    const setActiveDevice = (device?: MonitoringDeviceListData | null) => {
        store.activeDevice.value = device || null;
        LocalStorage.set('monitoring_last_device_id', device?.Id || '');
    };

    return {
        activeUser: store.activeUser,
        activeDevice: store.activeDevice,
        isMenuLoading: readonly(store.isMenuLoading),
        setIsMenuLoading,
        setActiveUser,
        setActiveDevice: setActiveDevice,
        storageUserId,
        storageDeviceId: storageDeviceId,
    };
};

export default useMonitoring;
