<script lang="ts">
export { useMonitoringDeviceWattRouterRelaysLoader } from '~/loaders/useMonitoringDeviceWattRouterRelaysLoader';
</script>

<script setup lang="ts">
import { useMonitoringDeviceWattRouterRelaysLoader } from '~/loaders/useMonitoringDeviceWattRouterRelaysLoader';
definePage({
    meta: {
        layout: 'default',
    },
});

const { isMenuLoading } = useMonitoring();

const { isLoading, data } = useMonitoringDeviceWattRouterRelaysLoader();
</script>

<template>
    <app-page padding :loading="isMenuLoading || (isLoading && !data)">
        <app-page-header title="Nastavení - Wattrouter" />

        <div class="row q-col-gutter-lg justify-center">
            <div class="col-12 col-lg-10 col-xl-6">
                <monitoring-device-wattrouter-card-expansion />
            </div>
        </div>
    </app-page>
</template>
