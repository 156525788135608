// import { Locale } from '~/plugins/i18n/_types';
import { dump } from '~/utils/debug';
import { parseEnvValueOrFail } from '~/boot/plugins/config/utils';
import { AppConfig, EnvMode } from '~/boot/plugins/config/_types';

const { DEV, MODE, VITE_API_URL } = import.meta.env;

const createConfig = () => {
    const config: AppConfig = {
        app: {
            // locale: parseEnvValue<Locale>(VITE_DEFAULT_LOCALE, 'cs'),
            // fallbackLocale: parseEnvValue<Locale>(VITE_FALLBACK_LOCALE, 'cs'),
            // availableLocales: ['cs', 'en'],
        },
        api: {
            baseURL: parseEnvValueOrFail<string>(VITE_API_URL),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Requested-With': 'XMLHttpRequest',
            },
        },
        // storage: {
        //     data: {
        //         auth: {
        //             key: 'auth_token',
        //         },
        //         locale: {
        //             key: 'lang',
        //         },
        //     },
        //     type: 'local',
        // },
        env: {
            dev: DEV,
            mode: MODE as EnvMode,
        },
    };

    // const isLocaleSupported = (locale: Locale | string): boolean =>
    //     config.app.availableLocales.includes(locale as Locale);

    dump('[ConfigService]', config);

    return Object.freeze({
        ...config,
        // isLocaleSupported,
    });
};

export default createConfig;
