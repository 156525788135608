<script setup lang="ts">
import { QTable } from 'quasar';
import { ManagementDataLiveData } from '~/api/endpoints/monitoring/data-live/_types';
import { DateTime } from 'luxon';
import useMonitoringDeviceDigiboxInfo from '~/composables/monitoring/device/useMonitoringDeviceDigiboxInfo';

definePage({
    meta: {
        layout: 'default', // potřeba kvůli potomkům jinak se zanoří layout
    },
});

const { isMenuLoading } = useMonitoring();

const { isLoading, data } = useMonitoringDeviceOverview();

const digiboxInfo = computed(() => data.value?.DigiBoxInfo);

const columns: QTable['columns'] = [
    {
        name: 'Name',
        label: 'Název',
        field: (row: ManagementDataLiveData) => row.DataPoint?.Name || row.DataPointKeyNumeric,
        align: 'left',
        sortable: true,
    },
    {
        name: 'Value',
        label: 'Hodnota',
        field: (row: ManagementDataLiveData) =>
            (row.Value * (row.DataPoint?.Factor || 0) + (row.DataPoint?.Offset || 0)).toLocaleString(undefined, {
                maximumFractionDigits: row.DataPoint?.Decimals || 0,
            }) + (row.DataPoint?.Unit ? ` ${row.DataPoint.Unit}` : ''),
        align: 'left',
        sortable: true,
    },
    {
        name: 'CreatedAt',
        label: 'Přijato',
        field: (row: ManagementDataLiveData) => row.CreatedAt.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
        align: 'left',
        sortable: true,
    },
];

const { hardwareLastConnectedAtStatusColor, hardwareLastConnectedAt } = useMonitoringDeviceDigiboxInfo(digiboxInfo);
</script>

<template>
    <app-page padding :loading="isMenuLoading">
        <app-page-header title="Data FVE - Informace" />

        <div class="row q-col-gutter-lg justify-center">
            <div class="col-12 col-lg-6">
                <monitoring-device-card-overview :loading="isLoading" :data="data" />
            </div>
            <div class="col-12 col-lg-6">
                <app-card title=" ">
                    <template #title>
                        <div class="row items-center justify-between">
                            <div class="text-h6">Přehled naposledy obdržených dat ze střídače</div>
                            <div :class="[`text-${hardwareLastConnectedAtStatusColor}`]">
                                {{ hardwareLastConnectedAt }}
                            </div>
                        </div>
                    </template>
                    <q-table
                        :rows="data?.DataLives || []"
                        :loading="isLoading"
                        :columns="columns"
                        hide-pagination
                        :pagination="{ rowsPerPage: 0 }"
                    >
                        <template #loading>
                            <q-inner-loading showing />
                        </template>

                        <template #body-cell-Actions="scope">
                            <q-td :props="scope" auto-width>
                                <div class="row justify-start q-col-gutter-sm no-wrap">
                                    <div>
                                        <app-btn color="primary" class="col-auto" flat dense icon="las la-pencil-alt">
                                            <q-tooltip> Upravit</q-tooltip>
                                        </app-btn>
                                    </div>
                                </div>
                            </q-td>
                        </template>
                    </q-table>
                </app-card>
            </div>
        </div>
    </app-page>
</template>
