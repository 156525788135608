<script setup lang="ts">
definePage({
    meta: {
        layout: 'auth',
    },
});
</script>

<template>
    <core-page-auth-login />
</template>
