import { afterEach, beforeEach } from './middlewares';
import { setupLayouts } from 'virtual:generated-layouts';
import { createRouter, createWebHistory } from 'vue-router/auto';

const appCreateRouter = () => {
    const router = createRouter({
        history: createWebHistory(),
        extendRoutes: (routes) => setupLayouts(routes),
    });

    router.beforeEach(beforeEach);
    router.afterEach(afterEach);

    return router;
};

export default appCreateRouter;
