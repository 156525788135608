import { AppBootItem } from '~/boot/_types';
// TODO: No typescript declaration file available: https://github.com/apexcharts/vue3-apexcharts/issues/2
// eslint-disable-next-line
// @ts-ignore
import VueApexCharts from 'vue3-apexcharts';
import ApexCharts from 'apexcharts';

export default {
    name: 'apexCharts',
    boot: async ({ app }) => {
        app.component('Apexchart', VueApexCharts);

        app.config.globalProperties.$apexcharts = ApexCharts;
    },
    priority: 5,
} satisfies AppBootItem;
